<template>
  <v-container fluid>
    <BreadHeader :title="model.add.name">
      <v-btn color="secondary" @click="redirectBack()">Back </v-btn>
    </BreadHeader>

    <transition name="fade">
      <v-alert
        dense
        border="left"
        type="warning"
        class="text-pre-wrap"
        v-if="alertError.length > 0"
        ><span
          class="d-block"
          v-for="(error, index) in alertError"
          :key="index"
          >{{ error }}</span
        ></v-alert
      >

      <v-alert
        dense
        border="left"
        icon="mdi-alert"
        type="error"
        class="text-pre-wrap"
        v-if="apiSubmit.error"
        ><span class="d-block">{{ apiSubmit.error }}</span></v-alert
      >
    </transition>

    <v-dialog v-model="generalInfoDialog" max-width="500">
      <v-card>
        <v-toolbar :color="HRDC_BLUE_THEME_COLOR" dark>
          {{ modalTitle }}
        </v-toolbar>

        <div class="pa-4">
          {{ modalText }}
        </div>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="green lighten-1"
            class="text-light"
            @click="modalGeneralInfo()"
          >
            Submit
          </v-btn>

          <v-btn
            color="red lighten-1"
            class="text-light"
            @click="generalInfoDialog = false"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!--BOC:[contents]-->
    <v-card>
      <v-tabs v-model="tab" background-color="#063058" dark>
        <v-tab :key="1" :href="`#tab-1`">
          General Remarks &nbsp;
          <v-icon>mdi-text-box-edit</v-icon>
        </v-tab>
        <v-tab :key="2" :href="`#tab-2`">
          Upload Attachment &nbsp;
          <v-icon>mdi-cloud-upload-outline</v-icon>
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item :key="1" :value="`tab-1`">
          <v-card flat>
            <v-card-text class="pa-0">
              <!-- Company Name -->
              <v-col class="d-flex pb-0" cols="12" sm="12" md="6">
                <v-text-field
                  dense
                  filled
                  label="Company"
                  v-model="companyName"
                  disabled
                ></v-text-field>
              </v-col>

              <!-- General Remarks -->
              <v-col class="d-flex py-0" cols="12" sm="12" md="12">
                <v-textarea
                  filled
                  label="General Remarks"
                  auto-grow
                  v-model="formData.generalRemarks"
                ></v-textarea>
              </v-col>

              <v-col class="d-flex justify-end pt-0">
                <v-btn
                  class="ma-2"
                  color="primary"
                  @click="submitGeneralRemarks()"
                  :loading="apiSubmit.isLoading"
                >
                  Submit
                </v-btn>
              </v-col>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item :key="2" :value="`tab-2`">
          <v-card flat>
            <!-- Attachment Name -->
            <v-col class="d-flex pb-0" cols="12" sm="12" md="6">
              <v-text-field
                dense
                filled
                label="Attachment Name"
                v-model.trim="formData.attachmentName"
                :error-messages="errorField.attachmentName"
              >
                <template v-slot:append>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on"> mdi-information </v-icon>
                    </template>
                    Etc: Authorization Letter, Credit Note, ...
                  </v-tooltip>
                </template>
              </v-text-field>
            </v-col>

            <!-- Attachment Type -->
            <v-col class="d-flex pb-0" cols="12" sm="12" md="6">
              <v-select
                dense
                filled
                :items="attachmentTypeItems"
                label="Attachment Type"
                v-model="formData.attachmentType"
                :error-messages="errorField.attachmentType"
              ></v-select>
            </v-col>

            <!-- Attachment File -->
            <v-col class="d-flex pb-0" cols="12" sm="12" md="6">
              <v-file-input
                :prepend-icon="null"
                prepend-inner-icon="mdi-paperclip"
                dense
                filled
                label="Attachment File"
                v-model="formData.attachmentFile"
                :error-messages="errorField.attachmentFile"
              >
              </v-file-input>
            </v-col>

            <v-col class="d-flex justify-end pt-0">
              <v-btn
                class="ma-2"
                color="primary"
                @click="submitGeneralAttachment()"
                :loading="apiSubmit.isLoading"
              >
                Submit
              </v-btn>
            </v-col>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>

    <HrdcLoader :loadingDialog="loadingDialog"></HrdcLoader>
  </v-container>
</template>

<script>
import Api from "@/objects/api";
import { mapState } from "vuex";
import Model from "@/objects/model";
import Service from "@/objects/service";
import hrdcFunction from "@/objects/hrdcFunction";
import HrdcData from "@/services/hrdc_einvoicing/objects/globalData";

export default {
  mixins: [hrdcFunction],
  computed: {
    ...mapState({
      auth: (state) => state.auth.data,
    }),
  },
  props: ["params"],
  data: () => ({
    apiGetApplicationSingleDetail: new Api(),
    apiSubmit: new Api(),
    apiSubmitGeneralAttachment: new Api(),
    model: new Model(),
    service: new Service(),
    hrdcData: new HrdcData(),
    alertError: [],
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    menu: false,
    formData: {
      applicationNo: null,
      generalRemarks: null,
      attachmentName: null,
      attachmentFile: null,
      attachmentType: null,
    },
    errorField: {
      applicationNo: null,
      generalRemarks: null,
      attachmentName: null,
      attachmentFile: null,
      attachmentType: null,
    },
    loadingDialog: false,
    generalInfoDialog: false,
    editMode: false,
    btnDisabled: false,
    companyName: null,
    applicationPrice: null,
    tab: "tab-1",
    modalTitle: null,
    modalText: null,
    modalMethod: "GENERAL_REMARKS",
    attachmentTypeItems: [],
  }),
  async created() {
    this.showLoadingDialog();
    this.formData.applicationNo = this.$route.query._ausk;
    this.model.getByKey(this.params.modelKey);
    if (this.model.serviceKey) this.service.set("key", this.model.serviceKey);

    // Assign apiDetails by mapping repository.
    const apiDetails = {
      apiSubmit: {
        url: `${
          this.$service[this.service.key]
        }/v1/en/console/general_info/create-general-remarks`,
        method: "post",
      },
      apiGetApplicationSingleDetail: {
        url: `${
          this.$service[this.service.key]
        }/v1/en/console/application/get-single-application`,
        method: "post",
        params: {
          applicationUuid: this.$route.query._ausk,
        },
      },
      apiSubmitGeneralAttachment: {
        url: `${
          this.$service[this.service.key]
        }/v1/en/console/general_info/create-general-attachment`,
        method: "post",
        params: {
          applicationUuid: this.$route.query._ausk,
        },
      },
    };

    for (const api in apiDetails) {
      if (apiDetails[api].url !== undefined) {
        this[api].setUrl(apiDetails[api].url);
      }
      if (apiDetails[api].method !== undefined) {
        this[api].setMethod(apiDetails[api].method);
      }
      if (apiDetails[api].params !== undefined) {
        this[api].setParams(apiDetails[api].params);
      }
    }

    this.stages = await this.hrdcData.allStages;
    this.rolesAssignation(this.auth.roles);

    this.apiGetApplicationSingleDetail.setCallbackCompleted((response) => {
      try {
        const { status, data } = response;

        if (status) {
          this.formData.generalRemarks = data.generalRemarks;
          this.companyName = data.oriCompanyName.toUpperCase();
          this.applicationPrice = data.totalPrice;
        }

        this.hideLoadingDialog();
      } catch (err) {
        console.log(err);
      }
    });
    this.apiGetApplicationSingleDetail.fetch();

    // Assign attachment type stages options.
    this.attachmentTypeItems = [
      {
        text: "HRDC Invoice",
        value: this.hrdcData.stagePriority.manualInvoiceToHrdc,
      },
      {
        text: "Client Invoice",
        value: this.hrdcData.stagePriority.autoGeneratedTaxpodInvoice,
      },
      {
        text: "Credit Note",
        value: this.hrdcData.stagePriority.creditNoteGenerated,
      },
    ];
  },
  methods: {
    clearErrorMsg() {
      for (let error in this.errorField) {
        this.errorField[error] = null;
      }
    },
    showErrorMessage(response) {
      for (const key in response.data) {
        this.errorField[key] = null;
        this.errorField[key] = response.data[key];
      }
    },
    modalGeneralInfo() {
      this.generalInfoDialog = !this.generalInfoDialog;

      switch (this.modalMethod) {
        case "GENERAL_REMARKS":
          this.submitGeneralRemarksApi();
          break;

        case "GENERAL_ATTACHMENT":
          this.submitGeneralAttachmentApi();
          break;
      }
    },
    redirectBack() {
      let route = "HrdcEinvoicingApplication";
      if (this.params.backTo) {
        route = this.params.backTo;
      }

      this.$store.commit("assignDeveloperData", {
        paginationPageNo: this.$route.query.currentPageNo,
      });

      this.$router.push({
        name: route,
        params: {
          modelKey: this.model.key,
          backTo: "",
        },
        query: {
          viewId: this.$store.state.hrdc_einvoicing.data.viewId,
          _ausk: this.$route.query._ausk,
        },
      });
    },
    submitGeneralRemarks() {
      this.generalInfoDialog = true;

      this.modalTitle = "Submit Remarks";
      this.modalText = "Are you sure to update the remarks ?";
      this.modalMethod = "GENERAL_REMARKS";
    },
    submitGeneralAttachment() {
      this.generalInfoDialog = true;

      this.modalTitle = "Submit General Attachment";
      this.modalText = "Are you sure to submit this attachment ?";
      this.modalMethod = "GENERAL_ATTACHMENT";
    },
    async submitGeneralRemarksApi() {
      this.showLoadingDialog();

      const fd = new FormData();
      fd.append("applicationUuid", this.formData.applicationNo);
      fd.append("referById", this.auth.uuid);
      fd.append("generalRemarks", this.formData.generalRemarks);

      this.apiSubmit.setParams(fd);
      this.apiSubmit.setCallbackCompleted((response) => {
        try {
          this.clearErrorMsg();
          if (!response.status) {
            this.showErrorMessage(response);
            this.$store.dispatch("showMessage", {
              message: response.message,
              messageType: "error",
              timeout: 2000,
            });
          }

          if (response.status) {
            this.$store.commit("assignDeveloperData", {
              paginationPageNo: this.$route.query.currentPageNo,
            });
            this.$router.push({
              name: "ViewHrdcEinvoicingApplication",
              query: {
                viewId: this.$store.state.hrdc_einvoicing.data.viewId,
                _ausk: this.$route.query._ausk,
              },
            });
            this.$store.dispatch("showMessage", {
              message: response.data,
              messageType: "success",
              timeout: 2000,
            });
          }
        } catch (err) {
          console.log(err);
        }
      });
      this.apiSubmit.fetch();
      this.hideLoadingDialog();
    },
    async submitGeneralAttachmentApi() {
      this.showLoadingDialog();

      // Set url & params.
      this.apiSubmit.setUrl(
        `${
          this.$service[this.service.key]
        }/v1/en/console/general_info/create-general-attachment`
      );

      const fd = new FormData();
      fd.append("applicationUuid", this.formData.applicationNo);
      fd.append("referById", this.auth.uuid);
      fd.append("attachmentName", this.formData.attachmentName);
      fd.append("attachmentFile", this.formData.attachmentFile);
      fd.append("attachmentType", this.formData.attachmentType);

      this.apiSubmit.setParams(fd);
      this.apiSubmit.setCallbackCompleted((response) => {
        try {
          this.clearErrorMsg();
          if (!response.status) {
            this.showErrorMessage(response);
            this.$store.dispatch("showMessage", {
              message: response.message,
              messageType: "error",
              timeout: 2000,
            });
          }

          if (response.status) {
            this.$store.commit("assignDeveloperData", {
              paginationPageNo: this.$route.query.currentPageNo,
            });
            this.$router.push({
              name: "HrdcEinvoicingApplication",
              query: {
                viewId: this.$store.state.hrdc_einvoicing.data.viewId,
                _ausk: this.$route.query._ausk,
              },
            });
            this.$store.dispatch("showMessage", {
              message: response.data,
              messageType: "success",
              timeout: 2000,
            });
          }
        } catch (err) {
          console.log(err);
        }
      });
      this.apiSubmit.fetch();
      this.hideLoadingDialog();
    },
  },
};
</script>
